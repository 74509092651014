@import './reset.css';

@font-face {
  font-family: 'Rift Demi';
  src: local('Rift Demi'), url(./fonts/hinted-Rift-Demi.ttf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aktiv Grotesk';
  src: local('Aktiv Grotesk'), url(./fonts/hinted-AktivGrotesk-Regular.ttf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aktiv Grotesk';
  src: local('Aktiv Grotesk'), url(./fonts/hinted-AktivGroteskEx-Bold.ttf) format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/*
@font-face {
  font-family: 'Aktiv Grotesk Ex';
  src: url("/themes/seventhsense/assets/fonts/hinted-AktivGroteskEx-Bold.woff2") format("woff2"), url("/themes/seventhsense/assets/fonts/hinted-AktivGroteskEx-Bold.woff") format("woff"), url("/themes/seventhsense/assets/fonts/hinted-AktivGroteskEx-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aktiv Grotesk Ex';
  src: url("/themes/seventhsense/assets/fonts/hinted-AktivGroteskEx-Regular.woff2") format("woff2"), url("/themes/seventhsense/assets/fonts/hinted-AktivGroteskEx-Regular.woff") format("woff"), url("/themes/seventhsense/assets/fonts/hinted-AktivGroteskEx-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/hinted-AktivGrotesk-Regular.ttf) format('opentype');
} */

/* @font-face {
    font-family: 'Lato';
    font-weight: 900;
    src: local('Lato'), url(./fonts/Lato-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Lato';
    font-weight: 900;
    src: local('Lato'), url(./fonts/Lato-Black.otf) format('opentype');
} */

html {
  font-size: 42.5%;
  scroll-behavior: smooth;
}

@media (min-width: 868px) {
  html {
    font-size: 62.5%
  }
}

body {
  margin: 0;
  overflow-x: hidden;
  background-color: #231E23;
  color: white;
  font-family: 'Aktiv Grotesk', Arial, serif;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Cookie control overrides */
.prebanner {
  min-width: fit-content !important;
}

.prebanner > svg {
  margin: 0 0 0 3px !important;
}

/* Stupid lightbox overrides */
.fslightbox-source {
  height: 95vh !important;
  width: 95% !important;
  overflow: auto;
}